import i18n from "../i18n"

function updateTranslations() {
  places[0] = i18n.t("constants.prematch")
  filterParams[0].label = i18n.t("constants.forks")
  filterParams[1].label = i18n.t("constants.matches")
  filterParams[2].label = i18n.t("constants.events")
  filterParams[3].label = i18n.t("constants.bookmakers")
  filterParams[4].label = i18n.t("constants.sports")
  filterParams[5].label = i18n.t("constants.time-events")
  filterParams[6].label = i18n.t("constants.type-values")
  dataFilter.timeEvent.data[0].label = i18n.t("constants.any-time")
  dataFilter.timeEvent.data[1].label = i18n.t("constants.one-hour")
  dataFilter.timeEvent.data[2].label = i18n.t("constants.two-hours")
  dataFilter.timeEvent.data[3].label = i18n.t("constants.twelve-hours")
  dataFilter.timeEvent.data[4].label = i18n.t("constants.one-day")
  dataFilter.timeEvent.data[5].label = i18n.t("constants.two-days")
  dataFilter.timeEvent.data[6].label = i18n.t("constants.seven-days")
  dataFilter.timeEvent.value.label = i18n.t("constants.any-time")
  dataFilter.place.data[0].label = i18n.t("constants.prematch")
  dataFilter.place.data[0].value = i18n.t("constants.prematch")
  dataFilter.place.value.label = i18n.t("constants.prematch")
  dataFilter.sports.data[0].label = i18n.t("constants.top")
  dataFilter.sports.data[0].filter = i18n.t("constants.top")
  dataFilter.sports.data[1].label = i18n.t("constants.football")
  dataFilter.sports.data[1].filter = i18n.t("constants.football")
  dataFilter.sports.data[2].label = i18n.t("constants.basketball")
  dataFilter.sports.data[2].filter = i18n.t("constants.basketball")
  dataFilter.sports.data[3].label = i18n.t("constants.tennis")
  dataFilter.sports.data[3].filter = i18n.t("constants.tennis")
  dataFilter.sports.data[4].label = i18n.t("constants.hockey")
  dataFilter.sports.data[4].filter = i18n.t("constants.hockey")
  dataFilter.sports.data[5].label = i18n.t("constants.volleyball")
  dataFilter.sports.data[5].filter = i18n.t("constants.volleyball")
  dataFilter.sports.data[6].label = i18n.t("constants.esport")
  dataFilter.sports.data[6].filter = i18n.t("constants.esport")
  dataFilter.sports.value = i18n.t("constants.top")
  statusActivate = i18n.t("constants.status-activate")
  registrationSuccessful = i18n.t("constants.registration-successful")
  changeInfoSuccessful = i18n.t("constants.change-info-successful")
  authSuccessful = i18n.t("constants.auth-successful")
  recoverySuccessful = i18n.t("constants.recovery-successful")
  changeSuccessful = i18n.t("constants.change-successful")
  logoutSuccessful = i18n.t("constants.logout-successful")
  activationSuccessful = i18n.t("constants.activation-successful")
  activationError = i18n.t("constants.activation-error")
  paymentError = i18n.t("constants.payment-error")
  contactUs = i18n.t("constants.contact-us")
  appeal = i18n.t("constants.appeal")
  logoutMessage = i18n.t("constants.logout-message")
  top = i18n.t("constants.top")
  fraction = i18n.t("constants.fraction")
  all = i18n.t("constants.all")
  hours = i18n.t("constants.hours")
  minutes = i18n.t("constants.minutes")
  seconds = i18n.t("constants.seconds")
}

if (i18n.isInitialized) {
  updateTranslations()
}

i18n.on("languageChanged", function (lng) {
  updateTranslations()
})

i18n.on("loaded", function (lng) {
  updateTranslations()
})

export const LIST_BK = {
  FONBET: "fonbet",
  MARATHON: "marathonbet",
  BETCITY: "betcity",
  MELBET: "melbet"
}

export const sortBkInStatRow = {
  [LIST_BK.BETCITY]: 1,
  [LIST_BK.FONBET]: 2,
  [LIST_BK.MARATHON]: 3,
  [LIST_BK.MELBET]: 4
}

export const listForkStatuses = {
  new: "is-new",
  favorite: "is-favorite",
  removed: "is-removed"
}

export const currenciesList = [{ label: "RUB", value: "RUB" }]

export const places = [i18n.t("constants.prematch")]

export const filterParams = [
  {
    id: 1,
    label: i18n.t("constants.forks"),
    name: "forks",
    value: "...",
    additional: "...",
    sort: 1
  },
  {
    id: 2,
    label: i18n.t("constants.matches"),
    name: "matches",
    value: "...",
    additional: "...",
    sort: 2
  },
  {
    id: 3,
    label: i18n.t("constants.events"),
    name: "events",
    value: "...",
    additional: "...",
    sort: 3
  },
  {
    id: 4,
    label: i18n.t("constants.bookmakers"),
    name: "bookmakers",
    value: "...",
    additional: "...",
    sort: 4
  },
  {
    id: 5,
    label: i18n.t("constants.sports"),
    name: "sports",
    value: "...",
    additional: "...",
    sort: 5
  },
  {
    id: 6,
    label: i18n.t("constants.time-events"),
    name: "timeEvents",
    value: "...",
    additional: "...",
    sort: 6
  },
  {
    id: 7,
    label: i18n.t("constants.type-values"),
    name: "typeValues",
    value: "...",
    additional: "...",
    sort: 7
  }
]

export const dataFilter = {
  sports: {
    data: [
      {
        label: i18n.t("constants.top"),
        type: "top",
        filter: i18n.t("constants.top")
      },
      {
        label: i18n.t("constants.football"),
        type: "football",
        filter: i18n.t("constants.football"),
        active: true
      },
      {
        label: i18n.t("constants.basketball"),
        type: "basketball",
        filter: i18n.t("constants.basketball"),
        active: true
      },
      {
        label: i18n.t("constants.tennis"),
        type: "tennis",
        filter: i18n.t("constants.tennis"),
        active: true
      },
      {
        label: i18n.t("constants.hockey"),
        type: "hockey",
        filter: i18n.t("constants.hockey"),
        active: true
      },
      {
        label: i18n.t("constants.volleyball"),
        type: "volleyball",
        filter: i18n.t("constants.volleyball"),
        active: true
      },
      {
        label: i18n.t("constants.esport"),
        type: "esport",
        filter: i18n.t("constants.esport"),
        active: true
      }
    ],
    value: i18n.t("constants.top")
  },
  profit: {
    data: [],
    value: 0
  },
  timeEvent: {
    data: [
      { label: i18n.t("constants.any-time"), value: "ANY_TIME" },
      { label: i18n.t("constants.one-hour"), value: "ONE_HOUR" },
      { label: i18n.t("constants.two-hours"), value: "TWO_HOURS" },
      { label: i18n.t("constants.twelve-hours"), value: "TWELVE_HOURS" },
      { label: i18n.t("constants.one-day"), value: "ONE_DAY" },
      { label: i18n.t("constants.two-days"), value: "TWO_DAYS" },
      { label: i18n.t("constants.seven-days"), value: "SEVEN_DAYS" }
    ],
    value: { label: i18n.t("constants.any-time"), value: "ANY_TIME" }
  },
  place: {
    data: [
      {
        label: i18n.t("constants.prematch"),
        value: i18n.t("constants.prematch"),
        name: "line",
        disabled: false
      }
    ],
    value: { label: i18n.t("constants.prematch"), name: "line" }
  },
  bks: {
    data: [],
    value: []
  },
  isFraction: {
    value: false
  },
  showAsian: {
    value: false
  },
  showMain: {
    value: true
  },
  showHandicap: {
    value: true
  },
  showTotal: {
    value: true
  },
  showIndividualTotal: {
    value: true
  },
  deleteTimeBets: {
    value: 0
  },
  count: {
    value: 100
  },
  eventId: {
    value: null
  }
}

export const countries = [
  { name: "Русский", code: "RU", value: "ru" },
  { name: "English", code: "US", value: "en" }
]

export let statusActivate = i18n.t("constants.status-activate")
export let registrationSuccessful = i18n.t("constants.registration-successful")
export let changeInfoSuccessful = i18n.t("constants.change-info-successful")
export let authSuccessful = i18n.t("constants.auth-successful")
export let recoverySuccessful = i18n.t("constants.recovery-successful")
export let changeSuccessful = i18n.t("constants.change-successful")
export let logoutSuccessful = i18n.t("constants.logout-successful")
export let activationSuccessful = i18n.t("constants.activation-successful")
export let activationError = i18n.t("constants.activation-error")
export let paymentError = i18n.t("constants.payment-error")
export let contactUs = i18n.t("constants.contact-us")
export let appeal = i18n.t("constants.appeal")
export let logoutMessage = i18n.t("constants.logout-message")
export let top = i18n.t("constants.top")
export let fraction = i18n.t("constants.fraction")
export let all = i18n.t("constants.all")
export let hours = i18n.t("constants.hours")
export let minutes = i18n.t("constants.minutes")
export let seconds = i18n.t("constants.seconds")
