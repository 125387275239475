import Main from "../../../../components/layout/main/Main"
import Register from "../../../../assets/public-pages/register-en.jpg"
import { useTranslation } from "react-i18next"

export const RulesEn = () => {
  const { t } = useTranslation()

  return (
    <Main>
      <h1 className="main__title">{t("pages.rules-title")}</h1>
      <h2>{t("pages.rules-1")}</h2>
      <p>{t("pages.rules-2")}</p>
      <p>{t("pages.rules-3")}</p>
      <img src={Register} alt="" />
      <h2>{t("pages.rules-4")}</h2>
      <p>{t("pages.rules-5")}</p>
      <h2>{t("pages.rules-6")}</h2>
      <p>{t("pages.rules-7")}</p>
    </Main>
  )
}
